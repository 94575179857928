function bootstrap() {
    let width;

    /*
     * trigger
     */
    function onLoad() {
        width = window.innerWidth;

        /* eslint-disable no-undef */
        header.init();
        giraSlider.init();
        textChange.init();
        topLink.init();
        tabSlider.init();
        customSelect.init();
        gallery.init();
        video.init();
        form.init();
    }

    function onResizeAndOrientationchange() {
        jQuery(window).on('resize orientationchange', () => {
            if (width !== window.innerWidth) {
                width = window.innerWidth;
            }
        });
    }

    /*
     * load trigger
     */
    jQuery(document).ready(() => {
        onLoad();
        onResizeAndOrientationchange();
    });
}

bootstrap();
