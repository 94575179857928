/* eslint-disable */
var topLink = function () {

    'use strict';

    var self = {};

    /* private properties */

    /* public properties */

    /* public methods */

    self.init = function () {
        // scroll to top page
        jQuery('.footer-top-button').click(function () {
            jQuery('html, body').animate({ scrollTop: 0});
        });

        window.addEventListener('scroll', this.changeView);
    };

    self.changeView = function () {
        // show/hide on top page
        var topLink = jQuery('.top-button');
        if (document.body.scrollTop || document.documentElement.scrollTop)  {
            if (topLink.css('display') === 'none') topLink.fadeIn(200);
        }
        else {
            topLink.fadeOut(100);
        }
    };

    /* private methods */

    return self;
};

window.topLink = new topLink();
