import scrollToElement from '../services/scrollService';

/* eslint-disable */

export default function accordion() {
    'use strict';

    const self = {};
    const items = jQuery('.content-faq-item');

    items.find('h5').on('click', nodeClick);

    function nodeClick(event) {
        const item = jQuery(this).parent();

        if (item.hasClass('active')) {
            item.find('.text').css('display', 'block').slideUp(350)
            item.removeClass('active');
        }
        else {
            var activeItems = items.filter('.active');
            activeItems.find('.text').css('display', 'block').slideUp(350);
            activeItems.removeClass('active');

            item.find('.text')
                .slideDown(350, () => {
                    item.addClass('active');
                    scrollToElement(event.target);
                });
        }
    }

    return self;
}
