/* eslint-disable */
export default function sideNav() {
    const sideNavItems = jQuery('.content-side-nav-item');

    function isDesktop() {
        return window.innerWidth > 767;
    }

    function tabClick() {
        const item = jQuery(this);

        if (isDesktop()) {
            if (!item.hasClass('active')) {
                item
                    .siblings('.active')
                    .removeClass('active')
                    .children('.content').fadeOut(0);

                item.addClass('active').children('.content').fadeIn(350);
            }
            setHeight();
        }
        else {
            nodeClick(item);
        }
    }

    function moveSlide() {
        const list = jQuery('.content-side-nav-item-list');
        const activeItems = list.find('.active');
        const activeHeight = activeItems.length ? activeItems[0].offsetHeight : 100;
        const slide = list.find('.tab-slide');
        let topPos = 0;

        sideNavItems.each(function(index) {
            if (jQuery(sideNavItems[index]).hasClass('active')) {
                return false;
            }
            topPos += sideNavItems[index].offsetHeight;
        });

        slide.css({
            top: topPos,
            height: activeHeight
        });
    }

    function setHeight() {
        const node = jQuery('.content-side-nav-item-list');

        if (isDesktop()) {
            const height = jQuery('.content-side-nav-item.active .content').height();
            const listHeight = node.height();

            node.css({
                height: (height > listHeight) ? height : listHeight + 'px'
            });
            // move slide line
            moveSlide();
        }
        else {
            node.css({
                height: 'auto'
            });
        }
    }

    function setLinks() {
        const links = jQuery('.content-side-nav-item-list a');
        if (isDesktop()) {
            links.removeClass('one-line-link').addClass('link-blue-square')
        }
        else {
            links.removeClass('link-blue-square').addClass('one-line-link')
        }
    }

    function nodeClick(item) {
        if (item.hasClass('active')) {
            item.find('.content').css('display', 'block').slideUp(350);
            item.removeClass('active');
        } else {
            const activeItems = item.siblings().filter('.active');
            activeItems.find('.content').css('display', 'block').slideUp(350);
            activeItems.removeClass('active');

            item.find('.content').slideDown(350);
            item.addClass('active');
        }
    }

    sideNavItems.on('click', tabClick);
    setHeight();
    setLinks();
}
