import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

function staggerElements(element) {
    const {staggerItemSelector} = element.dataset;

    if (!staggerItemSelector) {
        return;
    }

    gsap.defaults({
        ease: 'expo',
        duration: 1.5,
        stagger: 0.15,
    });

    gsap.set(staggerItemSelector, {y: 100, opacity: 0.3});

    ScrollTrigger.batch(staggerItemSelector, {
        scrub: true,
        start: '20px 80%',
        end: 'top top',
        onEnter: (batch) => gsap.to(batch, {
            opacity: 1,
            y: 0,
            stagger: {
                each: 0.15,
                grid: [1, 3],
            },
            overwrite: true,
        }),
    });

    ScrollTrigger.addEventListener('refreshInit', () => gsap.set(staggerItemSelector, {y: 0, opacity: 1}));
}

function fadeInMove(element) {
    gsap.to(element, {
        scrollTrigger: {
            trigger: element,
            start: 'top 70%',
            end: 'bottom 70%',
        },
        y: '-=40',
        opacity: 1,
        duration: 1,
    });
}

function move(element, distance) {
    gsap.to(element, {
        scrollTrigger: {
            trigger: element,
        },
        y: distance,
        duration: 3,
    });
}

function zoomImage(element) {
    const image = element.querySelector('img');

    gsap.to(image, {
        scrollTrigger: {
            trigger: image,
        },
        scale: 1.2,
        duration: 3,
    });

    image.addEventListener('load', () => {
        ScrollTrigger.refresh();
    });
}

export default function scrollAnimations() {
    gsap.registerPlugin(ScrollTrigger);

    const animationElements = Array.from(document.querySelectorAll('.js-scroll-animations'));

    ScrollTrigger.defaults({
        markers: false,
        scrub: 1,
        start: '-80% 4%',
        end: 'bottom 4%',
    });

    ScrollTrigger.saveStyles(animationElements);

    animationElements.forEach((element) => {
        const {scrollAnimationType} = element.dataset;

        if (!scrollAnimationType) {
            return;
        }

        ScrollTrigger.matchMedia({
            '(min-width: 1200px)': () => {
                switch (scrollAnimationType) {
                    case 'zoomImage':
                        zoomImage(element);
                        break;
                    case 'moveUp':
                        move(element, '-=80');
                        break;
                    case 'moveDown':
                        move(element, '+=80');
                        break;
                    case 'fadeInMove':
                        fadeInMove(element);
                        break;
                    case 'staggerElements':
                        staggerElements(element);
                        break;
                    default:
                        break;
                }
            },
        });
    });
}
