/* eslint-disable */
var video = function () {

    'use strict';

    var self = {};

    /* private properties */

    /* public methods */

    self.init = function () {
        jQuery('.video-item').on('click', playPause);
    };

    /* private methods */

    function playPause() {
        var node = jQuery(this);/* eslint-disable */
        var myVideo = node.find('video')[0];

        if (myVideo.paused) {
            myVideo.play();
            node.addClass('is-play');
        }
        else {
            myVideo.pause();
            node.removeClass('is-play');
        }
    }

    return self;
};

window.video = new video();
