/* eslint-disable */
var giraSlider = function () {

    'use strict';

    var self = {};
    var sliders = [];

    /* private properties */

    /* public methods */

    self.init = function () {
        var nodes = jQuery('.gira-slider');

        nodes.each(function () {
            sliders.push(new GiraSlider(this));
        });

        jQuery('.content-product-slider.slider-element .gira-slider-item').on('mouseenter', function (e) {
            if (isDesktop) {
                jQuery(this).css({
                    maxHeight: jQuery(this).height(),
                });
            }
        });
        jQuery('.content-product-slider.slider-element .gira-slider-item').on('mouseleave', function (e) {
            if (isDesktop) {
                setTimeout(function () {
                    jQuery(this).css({
                        maxHeight: 'none',
                    });
                }, 350);
            }
        });
    };

    self.redrawSliders = function () {
        setTimeout(function () {
            sliders.forEach(function (slider) {
                slider.redraw();
            });
        }, 400);
    }

    /* private methods */

    function GiraSlider(node) {
        var self = this;
        self.node = jQuery(node);
        self.items = self.node.find('.gira-slider-item');
        self.itemWidth = self.items.outerWidth(true);
        self.count = self.items.length;
        self.capacity = Math.ceil(self.node.innerWidth() / self.itemWidth);
        self.current = 0;

        self.next = self.node.find('.slider-next');
        self.prev = self.node.find('.slider-prev');

        self.next.on('click', next);
        self.prev.on('click', prev);

        setProductSliderCapacity();
        checkButtons();
        setHiddenItems();

        self.redraw = function () {
            self.itemWidth = self.items.outerWidth(true);
            self.capacity = Math.ceil(self.node.innerWidth() / self.itemWidth);
            self.current = 0;
            setProductSliderCapacity();
            checkButtons();
            setHiddenItems();
            self.items.css({
                transform: 'translateX(0)',
            });
        }

        function next() {
            if (self.next.hasClass('disabled')) {
                shake(self.next);
                return;
            }

            self.items.eq(self.current).addClass('hidden');
            self.items.eq(self.current + self.capacity).removeClass('hidden');
            self.current++;

            self.items.css({
                transform: getWidth(),
            });
            checkButtons();
        }

        function prev() {
            if (self.prev.hasClass('disabled')) {
                shake(self.prev);
                return;
            }

            self.current--;
            self.items.eq(self.current).removeClass('hidden');
            self.items.eq(self.current + self.capacity).addClass('hidden');

            self.items.css({
                transform: getWidth(),
            });
            checkButtons();
        }

        function shake(node) {
            node.addClass('shake')
            setTimeout(function () {
                node.removeClass('shake')
            }, 1000);
        }

        function getWidth() {
            var width = -self.itemWidth * self.current;

            if (isDesktop() && self.node.parent().hasClass('content-product-slider', 'news-product-slider') && self.current === self.count - self.capacity) {
                width += self.itemWidth * 0.25;
            }

            return 'translateX(' + width + 'px)'
        }

        function setProductSliderCapacity() {
            if (self.node.parent().hasClass('content-product-slider', 'news-product-slider') && isDesktop()) {
                self.capacity--;
            }
        }

        function checkButtons() {
            if (self.current === 0) {
                self.prev.addClass('disabled');
            } else {
                self.prev.removeClass('disabled');
            }
            if (self.current === self.count - self.capacity) {
                self.next.addClass('disabled');
            } else {
                self.next.removeClass('disabled');
            }
        }

        function setHiddenItems() {
            self.items.each(function () {
                var item = jQuery(this);

                if (item.index() > self.capacity - 1) {
                    item.addClass('hidden');
                } else {
                    item.removeClass('hidden');
                }
            });
        }
    }

    function isDesktop() {
        if (window.innerWidth > 1044) {
            return true
        }
    }

    return self;
};

window.giraSlider = new giraSlider();
