/* eslint-disable */
var customSelect = function () {

    'use strict';

    var self = {};

    /* public methods */

    self.init = function () {
        _initCustomSelect();
    };

    /* private methods */

    function _initCustomSelect() {
        jQuery('.custom-select-element').each(function() {
            var select = jQuery(this);

            if (select.children('.selected-item').length === 0) {
                new CustomSelect(jQuery(this));
            }
        });

        function CustomSelect(node) {
            var self = this;

            self.node = node;
            self.select = self.node.children('select')[0];

            jQuery(self.select).on('reset', function() {
                var value = self.select.options[0].value;
                var text = self.select.options[0].innerHTML;

                jQuery(self.select).val(value);
                self.selectedItem.html(text);
                self.selectItems.find('div').removeClass('selected').eq(0).addClass('selected');
            });

            var selected = self.select.options[self.select.selectedIndex];
            var selectedValue = selected ? selected.innerHTML.trim() : '';

            self.selectedItem = jQuery(document.createElement('div'))
                .addClass('selected-item')
                .html(selectedValue)
                .on('click', _clickSelect);

            self.selectItems = jQuery(document.createElement('div'))
                .addClass('select-items');

            self.node.append(self.selectedItem);
            self.node.append(self.selectItems);


            for (var i = 0, selectValue; i < self.select.length; i++) {
                selectValue = jQuery(document.createElement('div'))
                    .html(self.select.options[i].innerHTML.trim())
                    .data('index', self.select.options[i].value)
                    .on('click', _chooseValue);

                if (selectValue.text() === self.selectedItem.text()) {
                    selectValue.addClass('selected');
                }

                self.selectItems.append(selectValue);
            }

            function _clickSelect(e) {
                e.stopPropagation();
                var openNodes = jQuery('.custom-select-element.open');
                if (self.node.hasClass('open')) {
                    _closeSelect(null, self.node);
                }
                else if (openNodes.length) {
                    _closeSelect(null, openNodes);
                    _openSelect();
                }
                else {
                    _openSelect();
                }
            }

            function _chooseValue() {
                var jQuerythis = jQuery(this);

                jQuery(self.select).val(jQuerythis.data('index')).change();
                self.selectedItem.text(jQuerythis.text());
                jQuerythis.addClass('selected').siblings().removeClass('selected');
                _closeSelect(null, self.node);
            }

            function _openSelect() {
                self.node.addClass('open');
                self.selectItems.slideDown(200);
                jQuery('body').one("click", _closeSelect);
            }

            function _closeSelect(e, node) {
                if (!node) node = jQuery('.custom-select-element.open');
                node.find('.select-items').slideUp(200, function() {
                    node.removeClass('open');
                });
            }

        }

    }

    return self;
};

window.customSelect = new customSelect();
