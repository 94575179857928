export default function modal() {
    const triggerButtons = document.querySelectorAll('.js-modal-show');
    const closeButtons = document.querySelectorAll('.js-modal-close');

    triggerButtons.forEach((element) => {
        element.addEventListener('click', (event) => {
            const trigger = event.target;
            const modalType = trigger.dataset.modaltype;
            const modalMask = document.querySelector(`.js-modal-mask[data-modaltype='${modalType}']`);
            modalMask.classList.add('modal-active');
        });
    });

    function closeModal() {
        const closeMasks = document.querySelectorAll('.js-modal-mask');
        closeMasks.forEach((element) => {
            element.classList.remove('modal-active');
        });
    }

    closeButtons.forEach((element) => {
        element.addEventListener('click', () => {
            closeModal();
        });
    });

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    });
}
