import Slider from '../services/sliderService';

export default function defaultSlider() {
    const elements = document.querySelectorAll('.js-default-slider');
    const prevEl = document.querySelector('.js-default-slider-previous');
    const nextEl = document.querySelector('.js-default-slider-next');

    function shake(node) {
        node.classList.add('shake');
        setTimeout(() => {
            node.classList.remove('shake');
        }, 1000);
    }

    function createDefaultSlider(element) {
        const options = {
            slidesPerView: 1,
            watchSlidesVisibility: true,
            slideVisibleClass: 'default-slider__slider-item--visible',
            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                1044: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                },
            },
            navigation: {
                prevEl,
                nextEl,
            },
        };

        const swiper = new Slider(element, options); // eslint-disable-line no-new

        swiper.on('slideChange', () => {
            if (swiper.realIndex === 0) {
                shake(prevEl);
            }
        });

        swiper.on('reachEnd', () => {
            shake(nextEl);
        });
    }

    elements.forEach((element) => {
        createDefaultSlider(element);
    });
}
