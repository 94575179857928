/* eslint-disable */
var form = function () {

    'use strict';

    var self = {};

    /* private properties */
    const $searchNode = jQuery('.search-node');

    /* public methods */
    self.init = function () {
        jQuery('.content-form input').on('keyup', deleteButton);
        jQuery('.search-button input').on('click', startSearch);
        $searchNode.find('.close').on('click', endSearch);
    };

    function startSearch(e) {
        e.preventDefault();
        $searchNode.fadeIn(350);
        jQuery('.search-item input', $searchNode).focus();
    }

    function endSearch() {
        $searchNode.fadeOut(350);
    }

    /* private methods */
    function deleteButton() {
        var input = jQuery(jQuery(this));
        var item = jQuery(this).parent();
        var deleteBtn = null;

        item.removeClass('error').find('span').remove();

        if (this.value) {
            item.toggleClass('is-full');

            if (item.find('.delete').length == 0) {
                item.append('<div class="delete"></div>');

                item.find('.delete').on('click', function() {
                    input.val('');
                    item.find('.delete').remove();
                });
            }
        }
        else {
            item.find('.delete').remove();
        }
    }

    return self;
};

window.form = new form();
