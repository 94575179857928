/* eslint-disable */
// TODO: Fix Code style

var textChange = function () {

    'use strict';

    var self = {};

    /* private properties */


    /* public methods */

    self.init = function () {
        var textNodes = jQuery('.text-typing');
        var numberNodes = jQuery('.scale-up-number');

        textNodes.each(function() {
            textTyping(jQuery(this));
        });
        numberNodes.each(function() {
            numberScaleUp(jQuery(this));
        });
    };

    /* private methods */

    function textTyping(node) {
        var text = node.data('text');
        var textCount = text.length;
        var pos = 0;

        var typing = setInterval(function(){
            if (text[pos] == '*') node.html(node.html() + '<br>');
            else node.html(node.html() + text[pos]);
            pos++;
            if (pos === textCount) {
                clearInterval(typing);
                node.addClass('ready');
            }
        }, 100);
    }

    function numberScaleUp(node) {
        var countTo = node.attr('data-number');
        var func = getCurrentNumber;

        if (node.hasClass('with-plus')) {
            func = getCurrentNumberPoint;
        }

        jQuery({countNum: node.text()}).animate({
                countNum: countTo
            },
            {
                duration: 4000,
                easing: 'linear',
                step: function() {
                    node.text(func(this.countNum));
                },
                complete: function() {
                    node.text(func(this.countNum));
                }
            });
    }

    function getCurrentNumber(val) {
        return Math.floor(val)
    }

    function getCurrentNumberPoint(val) {
        val = Math.floor(val);
        if (val > 999) {
            val = String(val);
            return val.slice(0, -3) + '.' + val.substr(-3, 3)
        }
        return val
    }

    return self;
};

window.textChange = new textChange();
