/* eslint-disable */
var gallery = function () {

    'use strict';

    var self = {};

    /* private properties */
    var header;
    var nav;
    var contactBtn;

    /* public methods */

    self.init = function () {
        jQuery('.content-image-gallery img').on('mouseenter', _changeImage);
    };

    /* private methods */

    function _changeImage() {
        var img = jQuery(this);
        jQuery('.content-image-gallery .big-image img').attr('src', img.attr('src'))
    }

    return self;
};

window.gallery = new gallery();
