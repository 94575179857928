/* eslint-disable */
var tabSlider = function () {

    'use strict';

    var self = {};
    var wheelEvent;
    var sliders = [];

    /* private properties */

    /* public methods */

    self.init = function () {
        var nodes = jQuery('.content-tab-slider');

        nodes.each(function() {
            sliders.push(new TabSlider(this));
        })
    };

    self.redrawSliders = function() {
        setTimeout(function() {
            sliders.forEach(function(slider) {
                slider.redraw();
            });
        }, 400);
    }

    /* private methods */

    function TabSlider(node) {
        var self = this;
        self.node = jQuery(node);
        self.tabs = self.node.find('.tab-slider-li');
        self.titles = self.tabs.find('.tab-slider-title');
        self.inner = self.node.find('.inner');
        self.items = self.inner.find('.tab-slider-item');

        self.itemWidth = self.items.eq(1).outerWidth(true);
        self.itemWidthFirst = self.items.eq(0).outerWidth(true);
        self.maxWidth = self.itemWidth * (self.items.length - 2) + self.itemWidthFirst;

        self.current = 0;
        self.currentScroll = 0;

        self.titles.on('click', tabClick);

        moveSlide(self.tabs.eq(0));
        setHorScroll();


        self.redraw = function() {
            self.itemWidth = self.items.eq(1).outerWidth(true);
            self.itemWidthFirst = self.items.eq(0).outerWidth(true);
            self.maxWidth = self.itemWidth * (self.items.length - 2) + self.itemWidthFirst;
            self.current = 0;
            self.currentScroll = 0;

            self.node.find('.active').removeClass('active');
            self.tabs.eq(0).addClass('active');
            self.items.eq(0).addClass('active');
            moveSlide(self.tabs.eq(0));
            self.items.css({
                transform: 'translateX(0)'
            });
        }

        function tabClick() {
            if (!isDesktop()) return;
            var tab = jQuery(this).parent('.tab-slider-li');
            if (tab.hasClass('active')) return;

            setNewActive(tab.index());

            // move to current
            self.items.css({
                transform: getWidth()
            });
        }

        function moveSlide(node) {
            var slide = self.node.find('.tab-slide');
            var left = node.offset().left - node.parent().offset().left;
            var width = node.width();

            slide.css({
                left: left,
                width: width
            });
        }

        function getWidth(distance) {
            var width;
            // scroll
            if (distance) {
                width = self.currentScroll + distance;
            }
            // tab click
            else {
                width = (self.current === 0) ? 0 : self.itemWidthFirst + self.itemWidth * (self.current - 1);
            }
            if (width < 0) width = 0;
            else if (width > self.maxWidth) width = self.maxWidth;

            self.currentScroll = width;
            return 'translateX(' + -width + 'px)'
        }

        function setHorScroll() {
            // modern Chrome requires { passive: false } when adding event
            var supportsPassive = false;

            try {
                window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                    get: function () { supportsPassive = true; }
                }));
            }
            catch(e) {}

            var wheelOpt = supportsPassive ? { passive: false } : false;
            wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

            window.addEventListener('scroll', horScroll, wheelOpt); // modern desktop
            window.addEventListener('wheel', horScroll, wheelOpt); // modern desktop
            window.addEventListener('mousewheel', horScroll, wheelOpt); // modern desktop
        }

        function horScroll(e) {
            ;
            if (!isDesktop()) return;
            if (!checkHover(e.pageX, e.pageY)) {
                return;
            }

            var delta = e.wheelDelta > 0 ? -1 : 1;
            if (delta == -1 && self.currentScroll == 0 || delta == 1 && self.currentScroll == self.maxWidth) return
            if (e.preventDefault) e.preventDefault();
            e.stopPropagation();

            e.returnValue = false;
            var distance = 300 * delta;

            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                distance = 50 * delta;
            }

            self.items.css({
                transform: getWidth(distance)
            });
            var newActive = Math.round((self.currentScroll) / self.itemWidth);

            if (self.current != newActive) {
                setNewActive(newActive);
            }
        }

        function checkHover(X, Y) {
            var x = self.inner.offset().left;
            var y = self.inner.offset().top;

            if (Y > y && Y < y + self.inner.height() &&
                X > x && X < x + self.inner.width()) {
                return true;
            }
            return false;
        }

        function setNewActive(index) {
            // hide old
            self.items.eq(self.current).removeClass('active');
            self.tabs.eq(self.current).removeClass('active');

            // show new
            self.current = index;
            self.items.eq(self.current).addClass('active');
            self.tabs.eq(self.current).addClass('active');
            moveSlide(self.tabs.eq(self.current));
        }
    }

    function isDesktop() {
        if (window.innerWidth > 1044) {
            return true
        }
    }

    return self;
};

window.tabSlider = new tabSlider();
