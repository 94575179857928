export default function scrollToElement(element = null, animateScroll = true) {
    if (!element) {
        return;
    }

    const {top} = element.getBoundingClientRect();
    const position = {top: top + window.scrollY - 70};

    if (animateScroll) {
        position.behavior = 'smooth';
    }

    window.scrollTo(position);
}
