import Swiper, {
    Navigation,
    Pagination,
    Thumbs,
    Autoplay,
    Scrollbar,
} from 'swiper';

Swiper.use([Navigation, Pagination, Thumbs, Autoplay, Scrollbar]);

export default class Slider {
    /* eslint-disable indent */
    static defaultOptions = {
        slidesPerView: 'auto',
        spaceBetween: 32,
        keyboardControl: true,
        speed: 500,
        loop: false,
        a11y: {
            prevSlideMessage: 'Zurück',
            nextSlideMessage: 'Nächste',
        },
    };

    constructor(element, options) {
        // eslint-disable-next-line no-constructor-return
        return new Swiper(element, {...Slider.defaultOptions, ...options});
    }
}
