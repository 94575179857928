/*eslint-disable*/
var header = function () {

    'use strict';

    var self = {};

    /* private properties */
    var header;
    var nav;
    var contactBtn;

    /* public methods */

    self.init = function () {
        header = jQuery('header');
        nav = header.find('.header-navigation');
        contactBtn = header.find('.header-contact');

        jQuery('.mobile-menu-button').on('click', _mobileBtnClick);
        header.find('.nav-link.sub-link').on('click', _navLinkClick);
    };

    self.redrawHeader = function () {
        jQuery('header.open, header .open').removeClass('open');
        jQuery('html, body').css({
            overflow: 'auto',
        });
    }

    /* private methods */

    function _mobileBtnClick() {
        if (header.hasClass('open')) {
            header.removeClass('open');
            header.find('.open').removeClass('open');
            jQuery('html, body').css({
                overflow: 'auto',
            });
        } else {
            contactBtn.css({
                top: window.innerHeight - 140 + "px" // - 64 mobile
            });
            header.addClass('open');
            jQuery('html, body').css({
                overflow: 'hidden',
            });
        }
    }

    function _navLinkClick() {
        var li = jQuery(this).parent();

        if (li.hasClass('open')) {
            li.removeClass('open');
            nav.removeClass('open');
        } else {
            li.addClass('open');
            nav.addClass('open');
        }
    }

    return self;
};

window.header = new header();
