import Slider from '../services/sliderService';

export default function reviewSlider() {
    const elements = document.querySelectorAll('.js-review-slider');
    const prevEl = document.querySelectorAll('.js-review-slider-previous');
    const nextEl = document.querySelectorAll('.js-review-slider-next');

    function shake(node) {
        node.classList.add('shake');
        setTimeout(() => {
            node.classList.remove('shake');
        }, 1000);
    }

    function createReviewSlider(element) {
        const options = {
            slidesPerView: 1,
            watchSlidesVisibility: true,
            slideVisibleClass: 'review-slider__slider-item--visible',
            breakpoints: {
                1044: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                },
            },
            navigation: {
                prevEl: '.js-review-slider-previous',
                nextEl: '.js-review-slider-next',
            },
        };

        const swiper = new Slider(element, options); // eslint-disable-line no-new

        prevEl.forEach((prevElement) => {
            swiper.on('slideChange', () => {
                if (swiper.realIndex === 0) {
                    shake(prevElement);
                }
            });
        });

        nextEl.forEach((nextElement) => {
            swiper.on('reachEnd', () => {
                shake(nextElement);
            });
        });
    }

    elements.forEach((element) => {
        createReviewSlider(element);
    });
}
